/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../pages/errors/ErrorsPage'
import {App} from '../App'
import {useSelector} from 'react-redux'
import {RootState} from '../redux/rootReducer'
import RegisterPage from '../pages/register/RegisterPage'
import CreateAccountPage from '../pages/create-account'
import {getXAppName} from '../helper'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const RedirectToAuth = () => {
  useEffect(() => {
    const redirectUrl =
      window.location.origin + window.location.pathname + '|' + process.env.REACT_APP_NAME
    window.location.href =
      process.env.REACT_APP_AUTH_URL + '?redirect=' + btoa(redirectUrl) + '&mode=4'
  }, [])
  return (
    <div className='splash-screen'>
      <img
        src='https://static-cdn.giftano.com/assets/giftano-logo-horizontal.png'
        alt='Logo'
        height={60}
      />
      <span>Authenticating ...</span>
    </div>
  )
}

const AppRoutes: FC = () => {
  const isAuthorized = useSelector((state: RootState) => state.auth.session_id) ? true : false
  // console.log('isAuthorized', isAuthorized);

  // useEffect(() => {
  //   const ipclient = async () => {
    
  //   }
  //   ipclient()
  // }, [])

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='register' element={<CreateAccountPage />} />
          <Route path='create-account' element={<CreateAccountPage />} />
          {isAuthorized ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='*' element={<RedirectToAuth />} />{' '}
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
