import {CONFIG, CONNECTION, getXAppName} from '../../helper'

const path = process.env.REACT_APP_ENDPOINT_COUNTRY

export const getCountries = async () => {
  const config = CONFIG({url: path + '/countries'})
  return await CONNECTION(config)
}

export const getCountryProfile = async () => {
  let ipClient = await fetch('/ipinfo', {
    headers: {
      'Content-Type': 'application/json',
      'x-from-app-name': getXAppName(),
    },
  })
  await ipClient.json()
  const config = CONFIG({url: path + '/countryprofiles'})
  return await CONNECTION(config)
}
